  .popover-body .forgot-pwd-link {
    font-weight: 400;
    color: #B0B0B0 !important;
    text-decoration: none;
    margin-left: -10px;
  }

.popover-body  .login-btn {
    color: #fff;
    margin-left: -15px;
    font-size: small;
    background-color: #00C2C6;
    border-radius: 15px;
    border-color: #00C2C6;
    width: 100px;
    margin-bottom: 10px;
}

.login-err-msgs {
  list-style: none;
  margin-top: -22px;
}

button:focus {
  outline: 0 !important;
  border: 0 !important;
  box-shadow: none !important;
}